import { RHFControl, RHFPhoneInput, RHFSelect } from 'components/common/form';
import { Card, Col, Container, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import {
  GET_GUARDIAN_FATHER_STATUS,
  GET_GUARDIAN_MOTHER_STATUS,
  GET_GUARDIAN_RELATIONSHIP
} from '../queries';
import AddOptionModal from 'components/common/modals/AddOptionModal';
import { useState } from 'react';
import { useQuery } from '@apollo/client';

export const GuardianDetails = ({ sectionRef, sideNavLink }) => {
  const { data: fatherStatus } = useQuery(GET_GUARDIAN_FATHER_STATUS, {
    fetchPolicy: 'network-only'
  });

  const fatherStatusOptions = fatherStatus?.guardian_father_status?.map(
    ({ id, name }) => ({ value: id, label: name })
  );

  const { data: motherStatus } = useQuery(GET_GUARDIAN_MOTHER_STATUS, {
    fetchPolicy: 'network-only'
  });

  const motherStatusOptions = motherStatus?.guardian_mother_status?.map(
    ({ id, name }) => ({ value: id, label: name })
  );

  const { data: relationshipData } = useQuery(GET_GUARDIAN_RELATIONSHIP, {
    fetchPolicy: 'network-only'
  });

  const relationshipOptions = relationshipData?.guardian_relationShip?.map(
    ({ id, name }) => ({ value: id, label: name })
  );

  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState(null);

  const openModal = type => {
    setModalType(type);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setModalType(null);
  };

  return (
    <>
      {showModal && modalType === 'father' && (
        <AddOptionModal
          show={showModal}
          handleClose={closeModal}
          title="Guardian Father Status"
          entityName="guardian_father_status"
        />
      )}
      {showModal && modalType === 'mother' && (
        <AddOptionModal
          show={showModal}
          handleClose={closeModal}
          title="Guardian Mother Status"
          entityName="guardian_mother_status"
        />
      )}
      {showModal && modalType === 'relationship' && (
        <AddOptionModal
          show={showModal}
          handleClose={closeModal}
          title="Guardian Relationship"
          entityName="guardian_relationShip"
        />
      )}

      <Card
        className="border shadow-none mb-3"
        ref={sectionRef}
        id={sideNavLink}
      >
        <Card.Header className="pb-0">
          <div className="dFlexRowStartSpace pt-3">
            <Card.Title as="h5">Guardian Details</Card.Title>
          </div>
        </Card.Header>
        <Card.Body>
          <Container className="p-0 d-flex flex-column g-3">
            <Row className="gx-3">
              <Col xs={12} sm={6}>
                <RHFSelect
                  name="guardian.fatherStatus"
                  label="Father's Status"
                  options={fatherStatusOptions}
                  hasAddOption
                  onClickAdd={() => openModal('father')}
                />
              </Col>
              <Col xs={12} sm={6}>
                <RHFSelect
                  name="guardian.motherStatus"
                  label="Mother's Status"
                  options={motherStatusOptions}
                  hasAddOption
                  onClickAdd={() => openModal('mother')}
                />
              </Col>
            </Row>
            <hr />
            <Row className="gx-3">
              <Col xs={12} sm={6}>
                <RHFControl name="guardian.firstName" label="First Name" />
              </Col>
              <Col xs={12} sm={6}>
                <RHFControl name="guardian.middleName" label="Middle Name" />
              </Col>
            </Row>
            <Row className="gx-3">
              <Col xs={12} sm={6}>
                <RHFControl name="guardian.lastName" label="Last Name" />
              </Col>
              <Col xs={12} sm={6}>
                <RHFSelect
                  name="guardian.relationship"
                  label="RelationShip"
                  options={relationshipOptions}
                  hasAddOption
                  onClickAdd={() => openModal('relationship')}
                />
              </Col>
            </Row>
            <Row className="gx-3">
              <Col xs={12} sm={6}>
                <RHFPhoneInput name="guardian.phone" label="Contact Number" />
              </Col>
              <Col xs={12} sm={6}>
                <RHFControl name="guardian.email" label="Contact Email" />
              </Col>
            </Row>
          </Container>
        </Card.Body>
      </Card>
    </>
  );
};

GuardianDetails.propTypes = {
  sectionRef: PropTypes.object,
  sideNavLink: PropTypes.string
};
