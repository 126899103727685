import { gql } from '@apollo/client';

export const GET_DONOR_CATEGORY = gql`
  query GetDonorCategory {
    donor_category {
      id
      title
    }
  }
`;

export const DELETE_DONOR_CATEGORY = gql`
  mutation DeleteDonorCategory($id: uuid!) {
    delete_donor_category(where: { id: { _eq: $id } }) {
      returning {
        id
        title
      }
    }
  }
`;

export const INSERT_DONOR_CATEGORY = gql`
  mutation InsertDonorCategory($title: String!) {
    insert_donor_category_one(object: { title: $title }) {
      id
      title
    }
  }
`;

export const UPDATE_DONOR_CATEGORY = gql`
  mutation UpdateDonorCategory($title: String!, $id: uuid!) {
    update_donor_category(
      where: { id: { _eq: $id } }
      _set: { title: $title }
    ) {
      returning {
        id
        title
      }
    }
  }
`;
