import { gql, useLazyQuery, useMutation } from '@apollo/client';
import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
import TableRowAction from 'components/common/TableRowAction';
import CustomAdvanceTable from 'components/common/advance-table/CustomAdvanceTable';
import ConfirmationModal from 'components/common/modals/ConfirmationModal';
import { weDonarItems, weUserImage } from 'data/weraise-data/donor-data';
import { getDateRange, parseRouteParams } from 'helpers/utils';
import useBranchPermission from 'hooks/useBranchPermission';
import { useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import paths from 'routes/paths';
import { permissionsKeyMap } from 'routes/permissions/permissionKeys';
import DonorCounter from './DonorCounter';
import FilterModal from 'components/common/FilterModal';

/* -------------------------------------------------------------------------- */
export const GET_DONORS = gql`
  query GetDonors($where: donars_bool_exp!, $where1: orders_bool_exp!) {
    donars(where: $where, order_by: { created_at: desc }) {
      address
      consented_date
      consented_via
      contact_preferences
      created_at
      declaration_source
      email
      first_name
      gift_aid_elgibility
      gift_aid_file
      id
      last_name
      lead_source
      organisation_id
      phone
      remark
      title
      user_id
      simple_id
      orders_aggregate(where: $where1) {
        aggregate {
          sum {
            donation_amount
          }
        }
      }
    }
  }
`;
const DELETE_DONOR = gql`
  mutation DeleteDonor($id: uuid!) {
    delete_donars_by_pk(id: $id) {
      id
    }
  }
`;

const GET_TOTAL_DONORS = gql`
  query GetTotalDonor($where: donars_bool_exp = {}) {
    donars_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;
const GET_TOTAL_DONOR_BY_DATE = gql`
  query GetTotalDonorByDate(
    $where: donars_bool_exp = {}
    $where1: orders_bool_exp = {}
  ) {
    donars_aggregate(where: $where) {
      aggregate {
        count
      }
    }
    orders(where: $where1) {
      id
      donar {
        first_name
        last_name
      }
      donation_amount
    }
  }
`;

/* -------------------------------------------------------------------------- */

const columns = (handleDelete, navigate) => [
  {
    accessor: 'simple_id',
    Header: 'ID',
    cellProps: {
      className: 'py-2'
    }
  },
  {
    accessor: 'name',
    Header: 'Name',
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { id, name } = rowData.row.original;
      return (
        <Flex alignItems="center">
          <Avatar src={weUserImage} rounded="circle" size="xl" />
          <Link
            to={`/donors/${id}`}
            className="fw-medium ms-2 text-1100 hover-primary"
          >
            {name}
          </Link>
        </Flex>
      );
    }
  },
  {
    accessor: 'email',
    Header: 'Email',
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { email } = rowData.row.original;
      return (
        <a className="fw-medium text-600" href={'mailto:' + email}>
          {email}
        </a>
      );
    }
  },
  {
    accessor: 'phone',
    Header: 'Phone',
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { phone } = rowData.row.original;

      return (
        <a className="fw-medium text-600" href={'callTo:' + phone}>
          {phone}
        </a>
      );
    }
  },
  {
    accessor: 'address',
    Header: 'Address',
    cellProps: {
      className: 'fw-medium text-600 py-2'
    },
    Cell: rowData => {
      const { address } = rowData.row.original;
      return <span className="fw-medium text-600">{address}</span>;
    }
  },
  {
    accessor: 'payment',
    Header: 'Payment',
    cellProps: {
      className: 'fw-medium py-2'
    },

    Cell: rowData => (
      <SoftBadge pill bg={'success'} className="me-2 px-2">
        {rowData.row.original.payment}
      </SoftBadge>
    )
  },
  {
    accessor: 'id_',
    Header: '',
    cellProps: {
      className: 'py-2 text-end'
    },
    disableSortBy: true,

    Cell: rowData => {
      const { id } = rowData.row;
      return (
        <TableRowAction
          onCLickEdit={() =>
            navigate(parseRouteParams(paths.editDonor, { id }))
          }
          onCLickDelete={() => handleDelete(id)}
          subject={permissionsKeyMap.donor.children.donors}
        />
      );
    }
  }
];

const useQueryParams = () => {
  return new URLSearchParams(useLocation().search);
};

const Donors = () => {
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const queryParams = useQueryParams();

  const [getTotalDonor, { data: totalDonors }] = useLazyQuery(
    GET_TOTAL_DONORS,
    { fetchPolicy: 'network-only' }
  );
  const [getTotalDonorByDate, { data: totalDonorsByDate }] = useLazyQuery(
    GET_TOTAL_DONOR_BY_DATE
  );
  const [getTotalDonorByWeek, { data: totalDonorsByDateWeek }] = useLazyQuery(
    GET_TOTAL_DONOR_BY_DATE
  );
  const { where } = useBranchPermission();
  const [monthlyHighest, setMonthlyHighest] = useState({});
  const [weeklyHighest, setWeeklyHighest] = useState({});

  useEffect(() => {
    if (totalDonorsByDate && totalDonorsByDate.orders.length > 0)
      setMonthlyHighest(
        totalDonorsByDate.orders.reduce((prev, current) =>
          prev && prev.donation_amount > current.donation_amount
            ? prev
            : current
        )
      );
    if (totalDonorsByDateWeek && totalDonorsByDateWeek.orders.length > 0)
      setWeeklyHighest(
        totalDonorsByDateWeek.orders.reduce((prev, current) =>
          prev && prev.donation_amount > current.donation_amount
            ? prev
            : current
        )
      );
  }, [totalDonorsByDate, totalDonorsByDateWeek]);

  const [getDonors, { data, loading }] = useLazyQuery(GET_DONORS);

  const simpleIdParams = useMemo(() => {
    return queryParams.get('simple_id')?.split(',') || [];
  }, [queryParams]);

  useEffect(() => {
    const newWhere = structuredClone(where);

    if (simpleIdParams.length > 0) {
      newWhere.simple_id = { _in: simpleIdParams.map(id => parseInt(id, 10)) };
    }

    if (Object.keys(newWhere).length) {
      getDonors({ variables: { where: newWhere, where1: where } });
    }
  }, [where, getDonors, JSON.stringify(simpleIdParams)]);

  // useEffect(() => {
  //   console.log(simpleIdParams, where);
  //   if (Object.keys(where).length)
  //     getDonors({ variables: { where, where1: where } });
  // }, [where, getDonors]);

  const [deleteDonor, { loading: deleteLoading }] = useMutation(DELETE_DONOR, {
    onCompleted: () => {
      handleDeleteModalHide();
      toast.success('Donor deleted successfully');
    },
    onError: error => {
      toast.error('Something went wrong! Please try again');
      console.error(error);
    },
    update: (cache, { data }) => {
      const existingDonars = cache.readQuery({
        query: GET_DONORS,
        variables: { where }
      });
      if (!existingDonars) return;
      const newDonars = existingDonars.donars.filter(
        donor => donor.id !== data.delete_donars_by_pk.id
      );

      cache.writeQuery({
        query: GET_DONORS,
        variables: { where },
        data: { donars: newDonars }
      });
    }
  });

  useEffect(() => {
    if (Object.keys(where).length) {
      const { startDate, endDate } = getDateRange('month');
      getTotalDonor({
        variables: { where }
      });
      let totalDonorFilter = structuredClone({ where });
      totalDonorFilter.where.created_at = {
        _gte: startDate,
        _lte: endDate
      };
      getTotalDonorByDate({
        variables: {
          where: totalDonorFilter.where,
          where1: totalDonorFilter.where
        }
      });
      const { startDate: startDateWeek, endDate: endDateWeek } =
        getDateRange('week');
      getTotalDonor({
        variables: { where }
      });
      totalDonorFilter = structuredClone({ where });

      totalDonorFilter.where.created_at = {
        _gte: startDateWeek,
        _lte: endDateWeek
      };
      getTotalDonorByWeek({
        variables: {
          where: totalDonorFilter.where,
          where1: totalDonorFilter.where
        }
      });
    }
  }, [getTotalDonor, getTotalDonorByDate, getTotalDonorByWeek, where]);

  const tableData = useMemo(() => {
    return data?.donars.map(item => {
      const primaryEmail = item.email.find(email => email.isPrimary);
      const phone = item.phone.find(phone => phone.isPrimary);
      const primaryAddress = item.address.find(address => address.isPrimary);
      return {
        key: item.id,
        id: item.id,
        simple_id: item.simple_id,
        name: item.first_name + ' ' + item.last_name,
        email: primaryEmail === undefined ? '' : primaryEmail.email,
        phone: phone === undefined ? '' : phone.phoneNumber,
        address:
          primaryAddress === undefined
            ? ''
            : `${primaryAddress.addressLine1},
        ${primaryAddress.city}, ${primaryAddress?.country},
        ${primaryAddress.postcode}`,
        payment: `£${item?.orders_aggregate.aggregate.sum.donation_amount ?? 0}`
      };
    });
  }, [data]);

  const handleDeleteClick = id => {
    setSelectedId(id);
    setShowDeleteModal(true);
  };
  const handleDeleteModalHide = () => {
    setSelectedId(null);
    setShowDeleteModal(false);
  };
  const handleConfirmDelete = () => {
    deleteDonor({
      variables: {
        id: selectedId
      }
    });
  };

  const handleFilter = () => {
    setShowFilterModal(true);
  };

  const getDonorFilterRange = option => {
    const now = new Date();
    let startDate;
    let endDate;

    switch (option) {
      case 'today':
        startDate = new Date(now.setHours(0, 0, 0, 0)).toISOString();
        endDate = new Date(now.setHours(23, 59, 59, 999)).toISOString();
        break;

      case 'week':
        endDate = new Date(now.setHours(23, 59, 59, 999)).toISOString();
        const startOfWeek = new Date();
        startOfWeek.setDate(now.getDate() - 7);
        startOfWeek.setHours(0, 0, 0, 0);
        startDate = startOfWeek.toISOString();
        break;

      case 'month':
        startDate = new Date(
          now.getFullYear(),
          now.getMonth(),
          1
        ).toISOString();
        endDate = new Date(
          now.getFullYear(),
          now.getMonth() + 1,
          0
        ).toISOString();
        break;

      default:
        // eslint-disable-next-line no-multi-assign
        startDate = endDate = null;
    }
    return { startDate, endDate };
  };

  const applyFilter = async (dateRangeValue, data) => {
    const { startDate, endDate } = getDonorFilterRange(dateRangeValue || '');
    const newWhere = structuredClone(where);
    if (startDate && endDate) {
      newWhere.created_at = {
        _gte: startDate,
        _lte: endDate
      };
    } else if (
      dateRangeValue === 'select_date' &&
      data.startDate &&
      data.endDate
    ) {
      const startOfSelectedDate = new Date(data.startDate).setHours(0, 0, 0, 0);
      const endOfSelectedDate = new Date(data.endDate).setHours(
        23,
        59,
        59,
        999
      );
      newWhere.created_at = {
        _gte: new Date(startOfSelectedDate).toISOString(),
        _lte: new Date(endOfSelectedDate).toISOString()
      };
    } else {
      newWhere.created_at = {};
    }

    await getDonors({ variables: { where: newWhere, where1: where } });
  };

  return (
    <>
      <ConfirmationModal
        show={showDeleteModal}
        onHide={handleDeleteModalHide}
        loading={deleteLoading}
        title="Delete Donor"
        handleConfirm={handleConfirmDelete}
        body="Are you sure you want to delete selected donor?"
      />

      {showFilterModal && (
        <FilterModal
          show={showFilterModal}
          onHide={() => setShowFilterModal(false)}
          applyFilter={applyFilter}
        />
      )}

      <Row>
        <Col>
          <DonorCounter
            counterData={weDonarItems({
              totalDonors: totalDonors?.donars_aggregate?.aggregate?.count,
              newDonor: totalDonorsByDate?.donars_aggregate?.aggregate?.count,
              monthlyHighest: monthlyHighest ?? {},
              weeklyHighest: weeklyHighest ?? {}
            })}
            // notifications={weDonarNotifications}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12} xxl={12} className="h-md-100">
          {tableData && (
            <CustomAdvanceTable
              data={tableData ?? []}
              columns={columns(handleDeleteClick, navigate)}
              title="Donors list"
              addButtonLabel="Add New Donor"
              subject={permissionsKeyMap.donor.children.donors}
              showSearchInput
              searchInputPlaceHolder="Search Accounts"
              onClickAddNew={() => navigate(paths.addNewDonar)}
              loading={loading}
              addNew
              hasFilters
              handleFilter={handleFilter}
            />
          )}
        </Col>
      </Row>
    </>
  );
};

export default Donors;
