import { useQuery } from '@apollo/client';
import { GET_DONOR_BY_ID } from 'components/beneficiaries/donor/EditDonor';
import React from 'react';
import { useParams } from 'react-router-dom';
import ProfileDonorInfo from './donor-profile-components/ProfileDonorInfo';
import ContactPreference from 'components/donor/donor-form/ContactPreference';

// import ProfileCard from './ProfileCard';
// import CardNav from './CardNav';
// import ContactPreference from 'components/donor/donor-form/ContactPreference';
// import IconButton from 'components/common/IconButton';
// import paths from 'routes/paths';
// import { Can } from 'routes/permissions/Can';
// import {
//   permissionsKeyMap,
//   userActions
// } from 'routes/permissions/permissionKeys';
// import RelatedContacts from './donor-profile-components/RelatedContacts';

const DonorProfile = () => {
  const { id } = useParams();
  const { data: donorData } = useQuery(GET_DONOR_BY_ID, {
    variables: { id }
  });

  return (
    <>
      <ProfileDonorInfo donorData={donorData} />
      {donorData && <ContactPreference donorData={donorData} />}

      {/* <Card className="mb-3">
        <FalconCardHeader
          className="my-1"
          title={
            <IconButton
              onClick={() => navigate(paths.donors)}
              icon={'arrow-left'}
              iconClassName="me-2"
              variant="link"
              className="px-0 fw-bold fs--1 fs-md-0"
            >
              Back To Donor List
            </IconButton>
          }
          light={false}
          titleTag="div"
          menuClassName="align-items-center"
          endEl={
            <Can
              I={userActions.create}
              a={permissionsKeyMap.donation.children.donations}
            >
              <IconButton
                icon="plus"
                transform="shrink-3"
                onClick={() =>
                  navigate(
                    parseRouteParams(
                      paths.configNewDonation,
                      {},
                      {
                        donorId: id
                      }
                    )
                  )
                }
              >
                <span className="d-none d-md-inline-block ms-2">
                  Add Donation
                </span>
              </IconButton>
            </Can>
          }
        />
      </Card> */}
      {/* <ContactPreference
            contact_preferences={donorData?.donars_by_pk?.contact_preferences}
          /> */}
      {/* <RelatedContacts /> */}
    </>
  );
};

export default DonorProfile;
