import React, { useState, useEffect } from 'react';
import {
  Modal,
  Button,
  Form,
  InputGroup,
  ListGroup,
  Dropdown
} from 'react-bootstrap';
import { useQuery, useMutation } from '@apollo/client';
import {
  GET_DONOR_CATEGORY,
  DELETE_DONOR_CATEGORY,
  INSERT_DONOR_CATEGORY,
  UPDATE_DONOR_CATEGORY
} from '../queries';
import CardDropdown from 'components/common/CardDropdown';
import PropTypes from 'prop-types';

function DonorGroupModal({ show, handleClose }) {
  const { data, refetch } = useQuery(GET_DONOR_CATEGORY);
  const [deleteDonorCategory] = useMutation(DELETE_DONOR_CATEGORY);
  const [insertDonorCategory] = useMutation(INSERT_DONOR_CATEGORY);
  const [updateDonorCategory] = useMutation(UPDATE_DONOR_CATEGORY);

  const [categories, setCategories] = useState([]);
  const [newCategory, setNewCategory] = useState('');
  const [editIndex, setEditIndex] = useState(null);
  const [editCategory, setEditCategory] = useState('');

  useEffect(() => {
    if (data && data.donor_category) {
      setCategories(data.donor_category);
    }
  }, [data]);

  const handleAddCategory = async () => {
    if (newCategory.trim()) {
      await insertDonorCategory({ variables: { title: newCategory } });
      refetch();
      setNewCategory('');
    }
  };

  const handleDeleteCategory = async id => {
    await deleteDonorCategory({ variables: { id } });
    refetch();
  };

  const handleUpdateCategory = async id => {
    if (editCategory.trim()) {
      await updateDonorCategory({ variables: { title: editCategory, id } });
      setEditIndex(null);
      setEditCategory('');
      refetch();
    }
  };

  const startEditCategory = index => {
    setEditIndex(index);
    setEditCategory(categories[index].title);
  };

  const cancelEdit = () => {
    setEditIndex(null);
    setEditCategory('');
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Donor Group</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <InputGroup className="mb-4">
          <Form.Control
            type="text"
            placeholder="Add Category"
            value={newCategory}
            onChange={e => setNewCategory(e.target.value)}
          />
          <Button variant="primary" onClick={handleAddCategory}>
            + Add
          </Button>
        </InputGroup>
        <ListGroup>
          {categories.map((category, index) => (
            <ListGroup.Item
              key={category.id}
              className="d-flex justify-content-between align-items-center py-2 px-3"
              style={{
                border: '1px solid #ccc',
                borderRadius: '5px',
                marginBottom: '20px'
              }}
            >
              {editIndex === index ? (
                <InputGroup>
                  <Form.Control
                    type="text"
                    value={editCategory}
                    onChange={e => setEditCategory(e.target.value)}
                  />
                  <Button
                    variant="outline-primary"
                    onClick={() => handleUpdateCategory(category.id)}
                  >
                    Save
                  </Button>
                  <Button variant="outline-secondary" onClick={cancelEdit}>
                    Cancel
                  </Button>
                </InputGroup>
              ) : (
                <>
                  <span>{category.title}</span>

                  <CardDropdown>
                    <div className="py-2">
                      <Dropdown.Item onClick={() => startEditCategory(index)}>
                        Edit
                      </Dropdown.Item>
                      <Dropdown.Item
                        className="text-danger"
                        onClick={() => handleDeleteCategory(category.id)}
                      >
                        Delete
                      </Dropdown.Item>
                    </div>
                  </CardDropdown>
                </>
              )}
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleClose} className="w-100">
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default DonorGroupModal;

DonorGroupModal.propTypes = {
  handleClose: PropTypes.func,
  show: PropTypes.bool
};
