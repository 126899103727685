import React from 'react';
import paths from 'routes/paths';
import NavBar from 'components/common/profile/sideNavBar';

const DonorNav = () => {
  const donorNavLinks = [
    { id: 1, title: 'Profile', link: paths.donorProfile },
    { id: 2, title: 'Insights', link: paths.donorInsights },
    { id: 3, title: 'Giving', link: paths.donorGiving },
    { id: 4, title: 'Sponsorships', link: paths.donorSponsorships },
    {
      id: 5,
      title: 'Communications',
      link: paths.donorCommunications
    },
    {
      id: 6,
      title: 'Relation Management',
      link: paths.donorRelationManagement
    },
    { id: 7, title: 'Gift Aid', link: paths.donorGiftAid },
    { id: 8, title: 'Feedback', link: paths.donorFeedback }
  ];

  return <NavBar navLinks={donorNavLinks} />;
};

export default DonorNav;
