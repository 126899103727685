import * as Yup from 'yup';

export const donorScheme = Yup.object().shape({
  title: Yup.string().required('Required Field'),
  firstName: Yup.string().required('Required Field'),
  lastName: Yup.string().required('Required Field'),
  addresses: Yup.array().of(
    Yup.object().shape({
      addressLine1: Yup.string().required('Required Field'),
      addressLine2: Yup.string(),
      city: Yup.string().required('Required Field'),
      country: Yup.string().required('Required Field'),
      postcode: Yup.string().required('Required Field'),
      isPrimary: Yup.boolean().required('Required Field')
    })
  ),
  phoneNumbers: Yup.array().of(
    Yup.object().shape({
      phoneNumber: Yup.string().required('Required Field'),
      type: Yup.string().required('Required Field'),
      isPrimary: Yup.boolean().required('Required Field')
    })
  ),
  emails: Yup.array().of(
    Yup.object().shape({
      email: Yup.string().required('Required Field'),
      type: Yup.string().required('Required Field'),
      isPrimary: Yup.boolean().required('Required Field')
    })
  ),
  contactPreference: Yup.string(),
  // giftAid: Yup.object().shape({
  //   eligibility: Yup.boolean().required('Required Field'),
  //   date: Yup.string().required('Required Field'),
  //   validFrom: Yup.object().shape({
  //     month: Yup.string().required('Required Field'),
  //     day: Yup.string().required('Required Field'),
  //     year: Yup.string().required('Required Field')
  //   }),
  //   declarationSource: Yup.string().required('Required Field'),
  //   notes: Yup.string(),
  //   files: Yup.array()
  // }),
  lead_source: Yup.string()
});
